import FinanceApi from '@/services/FinanceApi';

const store = async (data) => {
    return FinanceApi.post('/certificate/refunds', data);
}

const deleteRefund = async (id) => {
    return FinanceApi.delete('/certificate/refunds/'+id);
}
export default {
    store,
    deleteRefund
}
