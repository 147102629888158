import FinanceApi from '@/services/FinanceApi';

const store = async (data) => {
    return FinanceApi.post('/certificate/payments', data);
}

const deletePayment = async (id) => {
    return FinanceApi.delete('/certificate/payments/'+id);
}
const getReceipt = async (number) => {
    return FinanceApi.get('/certificate/payments/receipt/' + number);
}
export default {
    store,
    deletePayment,
    getReceipt
}
