<template>
    <div ref="container">
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('payment_info')" :actions="actions" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('payment_info')" :actions="actions" :isFilter="false" />
            </template>
            <div v-if="data != null && data.customer != null">
                <div class="border px-5 pt-5 mb-5 rounded">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-md-6 mb-3"><label class="col-form-label mr-1 p-0">
                                        {{ $t('student_number') }}:</label>{{ data.customer.number }}
                                </div>
                                <div class="col-12 col-md-6 mb-3"><label class="col-form-label mr-1 p-0">
                                        {{ $t('national_number') }}:</label>{{ data.customer.national_id }}
                                </div>
                                <div class="col-12 mb-3"><label class="col-form-label mr-1 p-0">{{
                                    $t('name_surname')
                                }}:</label>{{ data.customer.name }}
                                    {{ data.customer.surname }}
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="col-form-label mr-1 p-0">
                                        {{ $t('student_group') }}:
                                    </label>
                                    <div v-if="checkPermission('customer_patch')" style="display: inline">
                                        <multi-selectbox :multiple="false" :allow-empty="false"
                                            :options="[{ value: 'TR', text: 'TR' }, { value: 'YU', text: 'YU' }]"
                                            v-on:optionSelected="customerGroupChanged"
                                                         style="width: 80px"
                                            v-model="data.customer.group"></multi-selectbox>
                                    </div>
                                    <div v-else>
                                        {{ data.customer.group }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="data && data.certificate_applications">
                <div v-for="plan in data.certificate_applications" v-bind:key="plan.id">
                <h6 class="text-uppercase mb-4">{{ getLocaleText(plan.certificate, 'name').toUpper() }}</h6>
                <b-table bordered striped responsive :items="[plan]" :fields="computedPriceFields(plan)"
                         class="mb-4 table-dropdown no-scrollbar border rounded">
                    <template #cell(price)="row">
                        <div
                            :class="row.item.is_cash == false && row.item.payments.length > 0 ? 'badge badge-danger' : ''">
                            {{ row.item.price | formatToCurrency(row.item.currency) }}
                        </div>
                    </template>
                    <template #cell(cash_price)="row">
                        <div :class="row.item.is_cash ? 'badge badge-danger' : ''">
                            {{ row.item.cash_price | formatToCurrency(row.item.currency) }}
                        </div>
                    </template>
                </b-table>
                <b-table v-if="plan.payments.length > 0" bordered striped responsive :items="plan.payments"
                         :fields="paymentFields" :tbody-tr-class="paymentRowClass" show-empty
                         class="mb-5 table-dropdown no-scrollbar border rounded">
                    <template #cell(due_date)="row">
                        <div v-if="row.item.parent_id != null">
                        </div>
                        <div v-else>
                            {{ row.item.due_date | dateFormat }}
                        </div>
                    </template>
                    <template #cell(amount)="row">
                                <span v-if="row.item.parent_id == null">{{
                                        row.item.amount | formatToCurrency(plan.currency)
                                    }}</span>
                    </template>
                    <template #cell(payment_type_name)="row">
                        {{ row.item.payment_type.name }}
                        <span v-if="row.item.is_cancelled" class="badge badge-danger right">{{toUpperCase('cancelled') }}</span>
                    </template>
                    <template #cell(dropdownTable)="row">
                        <div class="d-flex">
                            <i class="ri-information-fill ri-2x" v-if="row.item.explanation" style="font-size: 16px"
                               v-b-tooltip.hover :title="row.item.explanation"></i>


                            <b-button v-if="!row.item.ref_id && checkPermission('payment_delete')"
                                      class="text-danger" variant="link" size="xs" @click="deletePayment(row.item.id)">
                                {{ $t('delete').toUpper() }}
                            </b-button>
                        </div>
                    </template>
                    <template slot="bottom-row">
                        <td>
                            <span class="badge badge-danger" style="width: 100%">
                                {{toUpperCase(plan.is_cash ? 'cash' : 'with_installment')}}
                            </span>
                        </td>
                        <td></td>
                        <td>
                            <b>
                            {{ plan.payments_total | formatToCurrency(plan.currency) }}
                            </b>
                        </td>
                        <td><b>{{ plan.paid | formatToCurrency(plan.currency) }}</b></td>
                        <td colspan="4"><b>{{ $t('balance') }}:</b>
                            <b :class="plan.balance < 0 ? 'text-danger' : ''">
                            {{plan.balance | formatToCurrency(plan.currency) }}
                            </b>
                        </td>
                    </template>
                </b-table>

                    <b-table v-if="plan.refunds.length > 0" bordered striped responsive :items="plan.refunds"
                             :fields="refundFields" :tbody-tr-class="paymentRowClass" show-empty
                             class="mb-5 table-dropdown no-scrollbar border rounded">
                        <template #cell(payment_type_name)="row">
                            {{ row.item.payment_type.name }}
                        </template>
                        <template #cell(dropdownTable)="row">
                            <div class="d-flex">
                                <i class="ri-information-fill ri-2x" v-if="row.item.explanation" style="font-size: 16px"
                                   v-b-tooltip.hover :title="row.item.explanation"></i>


                                <b-button v-if="!row.item.ref_id && checkPermission('payment_delete')"
                                          class="text-danger" variant="link" size="xs" @click="deleteRefund(row.item.id)">
                                    {{ $t('delete').toUpper() }}
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                    <div class="d-flex mb-3" v-if="plan.refunds.length > 0">
                        <div class="mr-3">
                            <label class="col-form-label mr-1 p-0">{{ $t('price') }}:</label>
                            <b>{{
                                    plan.net_price | formatToCurrency(plan.currency)
                                }}</b>
                        </div>

                        <div class="mr-3">
                            <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                            <b>
                                {{plan.paid | formatToCurrency(plan.currency)}}
                            </b>
                        </div>

                        <div class="mr-3">
                            <label class="col-form-label mr-1 p-0">{{ $t('refund') }}:</label>
                            <b>
                                {{plan.refunds_total | formatToCurrency(plan.currency)}}
                            </b>
                        </div>

                        <div class="mr-3">
                            <label class="col-form-label mr-1 p-0">{{ $t('balance') }}:</label>
                            <b :class="plan.net_balance < 0 ? 'text-danger' : ''">
                                {{plan.net_balance | formatToCurrency(plan.currency)}}
                            </b>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-12 d-flex align-items-center mb-4">
                        <div class="row">
                            <div class="col-auto">
                                <b-button variant="outline-primary mr-3" @click="addPayment(plan)"><span
                                    class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                        $t('add_payment').toUpper() }}</span>
                                </b-button>
                            </div>
                            <div class="col-auto">
                                <b-button variant="outline-primary mr-3" @click="addRefund(plan)"><span
                                    class="d-flex"><i class="ri-add-line mr-2"></i>{{
                                        $t('add_refund').toUpper() }}</span>
                                </b-button>
                            </div>
                            <div class="col-auto">
                                <div class="d-flex custom-file-upload" v-if="getPrints(plan).length > 1">
                                    <multi-selectbox class="width-300" :multiple="false" v-model="plan.printId"
                                                     :options="getPrints(plan)"></multi-selectbox>
                                    <b-button variant="outline-secondary" class="btn-40" @click="selectPrint(plan)">
                                        <i class="ri-printer-line"></i>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <CommonModal id="addPaymentModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_payment') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="addPaymentForm">
                        <div class="row">
                            <div class="col-12">
                                <ValidationProvider name="is_cash" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('cash_or_installed')">
                                        <b-form-radio v-model="addPaymentForm.is_cash" :value="1">
                                            <span>{{ $t('cash') }}</span>
                                        </b-form-radio>
                                        <b-form-radio v-model="addPaymentForm.is_cash" :value="0">
                                            <span>{{ $t('with_installment') }}</span>
                                        </b-form-radio>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                                                 v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                                <b-form-input type="number" step=".01"
                                                              v-model="addPaymentForm.amount"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                                <currency-selectbox :validateError="errors[0]"
                                                                    v-model="addPaymentForm.currency">
                                                </currency-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency_rate" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                                <b-form-input type="number" step=".0001"
                                                              v-model="addPaymentForm.currency_rate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="due_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('due_date') }}</label>
                                                <b-form-input type="date"
                                                              v-model="addPaymentForm.due_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="payment_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('payment_date') }}</label>
                                                <b-form-input type="date"
                                                              v-model="addPaymentForm.payment_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                                <b-form-input v-model="addPaymentForm.explanation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="payment_method" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_method')">
                                        <multi-selectbox :multiple="false"
                                                         :options="[{ text: $t('wire'), value: 'wire' }, { text: $t('credit_card'), value: 'credit_card' }]"
                                                         v-model="addPaymentForm.payment_method" :validate-error="errors[0]">

                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="addPaymentForm.bank_id"
                                                        ref="otherPaymentBank">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12"
                                 v-if="addPaymentForm.payment_method && addPaymentForm.payment_method == 'wire'">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                                                 :is_bank_required="true" :bank_id="addPaymentForm.bank_id"
                                                                 :currency="addPaymentForm.currency"
                                                                 v-model="addPaymentForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                 v-if="addPaymentForm.payment_method && addPaymentForm.payment_method == 'credit_card'">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="payment_type_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('payment_type')">
                                                <payment-types-selectbox :validate-error="errors[0]" type="credit_card"
                                                                         :is_bank_required="true" :bank_id="addPaymentForm.bank_id"
                                                                         v-model="addPaymentForm.payment_type_id">
                                                </payment-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="provision" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('provision') }}</label>
                                                <b-form-input type="number"
                                                              v-model="addPaymentForm.related_number"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="terminalOptions">
                                        <ValidationProvider name="terminal" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('terminal')">
                                                <b-form-select :options="terminalOptions"
                                                               v-model="addPaymentForm.account_number"></b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="card_no" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('card_no')">
                                                <b-form-input autocomplete="false" v-mask="'####-****-****-####'"
                                                              v-model="addPaymentForm.related_info"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="savePayment" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="addRefundModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('add_refund') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="addRefundForm">
                        <div class="row">

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="amount" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('amount') }}</label>
                                                <b-form-input type="number" step=".01"
                                                              v-model="addRefundForm.amount"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency') }}</label>
                                                <currency-selectbox :validateError="errors[0]"
                                                                    v-model="addRefundForm.currency">
                                                </currency-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="currency_rate" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('currency_rate') }}</label>
                                                <b-form-input type="number" step=".0001"
                                                              v-model="addRefundForm.currency_rate"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="refund_date" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('refund_date') }}</label>
                                                <b-form-input type="date"
                                                              v-model="addRefundForm.refund_date"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="explanation" rules="" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('explanation') }}</label>
                                                <b-form-input v-model="addRefundForm.explanation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="payment_method" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_method')">
                                        <multi-selectbox :multiple="false"
                                                         :options="[{ text: $t('wire'), value: 'wire' }, { text: $t('credit_card'), value: 'credit_card' }]"
                                                         v-model="addRefundForm.payment_method" :validate-error="errors[0]">

                                        </multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="bank_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('bank')">
                                        <bank-selectbox :validate-error="errors[0]" v-model="addRefundForm.bank_id">
                                        </bank-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12"
                                 v-if="addRefundForm.payment_method && addRefundForm.payment_method == 'wire'">
                                <ValidationProvider name="payment_type_id" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('payment_type')">
                                        <payment-types-selectbox :validate-error="errors[0]" type="wire"
                                                                 :is_bank_required="true" :bank_id="addRefundForm.bank_id"
                                                                 :currency="addRefundForm.currency"
                                                                 v-model="addRefundForm.payment_type_id">
                                        </payment-types-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12"
                                 v-if="addRefundForm.payment_method && addRefundForm.payment_method == 'credit_card'">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="payment_type_id" rules="required"
                                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('payment_type')">
                                                <payment-types-selectbox :validate-error="errors[0]" type="credit_card"
                                                                         :is_bank_required="true" :bank_id="addRefundForm.bank_id"
                                                                         v-model="addRefundForm.payment_type_id">
                                                </payment-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="provision" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group>
                                                <label class="col-form-label pt-0">{{ $t('provision') }}</label>
                                                <b-form-input type="number"
                                                              v-model="addRefundForm.related_number"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="terminalOptions">
                                        <ValidationProvider name="terminal" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('terminal')">
                                                <b-form-select :options="terminalOptions"
                                                               v-model="addRefundForm.account_number"></b-form-select>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                                        <ValidationProvider name="card_no" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('card_no')">
                                                <b-form-input autocomplete="false" v-mask="'####-****-****-####'"
                                                              v-model="addRefundForm.related_info"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <b-button type="submit" @click="saveRefund" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>
            <CommonModal id="vinovModal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('vinov_instructions').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <VinovInstructions v-if="data && data.customer && showVinov" :customer_id="data.customer.id"
                        :student_program_id="student_program_id" :registration_id="registration_id"
                        :payment-plans="data.payment_plans">

                    </VinovInstructions>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import CommonModal from '@/components/elements/CommonModal';
import CustomerService from '@/services/CustomerService';
import DocumentService from '@/services/DocumentService';
import PaymentTypesSelectbox from '@/components/interactive-fields/PaymentTypesSelectbox';
import PaymentPeriodsSelectbox from '@/components/interactive-fields/PaymentPeriodsSelectbox';
import ScholarshipTypesSelectbox from '@/components/interactive-fields/ScholarshipTypesSelectbox';
import BankSelectbox from '@/components/interactive-fields/BankSelectbox';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import PaymentProductsSelectbox from '@/components/interactive-fields/PaymentProductsSelectbox';
import moment from 'moment';
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import CurrencySelectbox from '@/components/interactive-fields/CurrencySelectbox';
import CurrencyRateService from "@/services/CurrencyRateService";
import qs from "qs";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import ProformaInvoiceList from "@/modules/proformaInvoice/page/List";
import VinovInstructions from "@/modules/vinovInstructions/page/List";
import CustomerNoteList from "@/modules/customerNotes/page/List";
import {mapGetters} from "vuex";
import CertificatePaymentService from "@/services/CertificatePaymentService";
import CertificateRefundService from "@/services/CertificateRefundService";
import PaymentService from "@/services/PaymentService";

export default {
    name: 'Payments',
    props: {
        receipt_number: {
            type: String,
            default: '0'
        }
    },
    components: {
        AcademicYearsSelectbox,
        Header,
        HeaderMobile,
        CurrencySelectbox,
        ParameterSelectbox,
        MultiSelectbox,
        PaymentProductsSelectbox,
        AppLayout,
        CommonModal,
        PaymentPeriodsSelectbox,
        ScholarshipTypesSelectbox,
        PaymentTypesSelectbox,
        BankSelectbox,
        ProformaInvoiceList,
        ValidationProvider,
        ValidationObserver,
        CustomerNoteList,
        VinovInstructions
    },
    data() {
        return {
            number: this.$route.params.number,
            data: null,
            selectedPaymentPlan: null,
            selectedPaymentRow: null,
            allowPartial: true,
            paymentForm: {transaction_date:null},
            addPaymentForm: {},
            addRefundForm: {},
            terminalOptions: null,
            disabled: false,
            updating: false,
            size: 'lg',
            selected: null,
            CommonModalMode: false,
            checkSpinner: false,
            paymentControl: false,
            clicked: false,
            checkPayment: false,
            file1: null,
            printId: '0',
            printOptions: null,
            date: new Date(),
            showVinov: false,

            priceFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'gross_price',
                    label: this.toUpperCase('gross_price'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'discount_amount',
                    label: this.toUpperCase('discount_amount'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'price',
                    label: this.toUpperCase('with_installment'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'cash_price',
                    label: this.toUpperCase('cash'),
                    sortable: true,
                    formatter: this.formatToCurrency
                },
                {
                    key: 'certificate.start_date',
                    label: this.toUpperCase('due_date'),
                    sortable: true,
                    formatter: this.formatDate
                },
                { key: 'dropdownTable', label: '#', tdClass: 'width-100' }
            ],
            paymentFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'due_date',
                    label: this.toUpperCase('due_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount_to_be_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'amount_paid',
                    label: this.toUpperCase('amount_paid'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'balance',
                    label: this.toUpperCase('balance'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'payment_date',
                    label: this.toUpperCase('payment_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'payment_type_name', label: this.toUpperCase('payment_type') },
                {
                    key: 'transaction_date',
                    label: this.toUpperCase('transaction_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'dropdownTable', label: '#', thClass: 'width-150' }
            ],

            refundFields: [
                {
                    key: 'id',
                    label: 'ID',
                    thClass: 'width-50'
                },
                {
                    key: 'amount',
                    label: this.toUpperCase('amount'),
                    formatter: this.formatToCurrency
                },
                {
                    key: 'refund_date',
                    label: this.toUpperCase('refund_date'),
                    formatter: this.formatDate,
                    thClass: 'width-150'
                },
                { key: 'payment_type_name', label: this.toUpperCase('payment_type') },
                { key: 'dropdownTable', label: '#', thClass: 'width-150' }
            ],
            actions: null,
            user: {}
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        'addPaymentForm.payment_date'(newVal) {
            this.setCurrencyRate(this.addPaymentForm);
        },
        'addPaymentForm.currency'(newVal) {
            this.setCurrencyRate(this.addPaymentForm);
        },
        'addRefundForm.payment_date'(newVal) {
            this.setCurrencyRate(this.addRefundForm);
        },
        'addRefundForm.currency'(newVal) {
            this.setCurrencyRate(this.addRefundForm);
        },
    },
    methods: {
        setCurrencyRate(obj) {
            var date = null;
            if (typeof obj.payment_date != 'undefined') {
                date = obj.payment_date;
            }
            if (typeof obj.refund_date != 'undefined') {
                date = obj.refund_date;
            }

            if (!obj || obj.currency == 'TRY' || !date) {
                return;
            }
            CurrencyRateService.getByDate(date, obj.currency)
                .then(response => {
                    if (response.data.data && response.data.data.banknote_selling) {
                        obj.currency_rate = response.data.data.banknote_selling;
                    }
                });
        },
        loadData() {
            CustomerService.getCertificatePayments(this.number).then(this.dataCallback).catch(e => {
                this.showErrors(e);
            });
        },
        dataCallback(response) {
            this.data = response.data.data;
            this.actions = [
                {
                    text: this.$t('vinov_instructions'),
                    permission: 'vinovinstruction_index',
                    callback: () => {
                        this.showVinov = true;
                        this.$bvModal.show('vinovModal');
                    },
                    show: () => {
                        return this.data.customer.group == 'TR';
                    }
                }
            ];
        },
        deleteDocument(uuid, period) {
            DocumentService.deleteDocumentByUuid(uuid)
                .then(response => {
                    if (response.data.success) {
                        this.data.documents[period][0].files;
                        var filtered = this.data.documents[period][0].files.filter(function (value, index) {
                            return value.uuid != uuid;
                        });
                        this.data.documents[period][0].files = filtered;
                    }
                })
        },
        addPayment(payment_plan) {
            this.selectedPaymentPlan = payment_plan;
            this.allowPartial = true;
            this.$bvModal.show('addPaymentModal');
            this.addPaymentForm = {};
            this.$set(this.addPaymentForm, 'certificate_application_id', payment_plan.id);
            this.$set(this.addPaymentForm, 'payments_total', payment_plan.payments_total);
            this.$set(this.addPaymentForm, 'payment_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.addPaymentForm, 'due_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.addPaymentForm, 'transaction_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.addPaymentForm, 'currency', payment_plan.currency);
            this.$set(this.addPaymentForm, 'currency_rate', this.data.currencies[payment_plan.currency]);
            this.$refs.addPaymentForm.reset();
        },
        async savePayment() {
            const isValid = await this.$refs.addPaymentForm.validate();
            if (isValid) {

                this.addPaymentForm.customer_id=this.data.customer.id;
                CertificatePaymentService.store(this.addPaymentForm)
                    .then(response => {
                        this.addPaymentForm = {};
                        this.$refs.addPaymentForm.reset();
                        this.toast(response.data);
                        this.$bvModal.hide('addPaymentModal');
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.addPaymentForm);
                    });
            }
        },
        async deletePayment(id) {
            this.deleteModal(() => {
                CertificatePaymentService.deletePayment(id)
                    .then(response => {
                        this.loadData();
                        if (response.data.success) {
                            this.toast(response.data);
                        } else {
                            this.$toast.error(this.$t('api.' + response.data.message));
                        }
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        addRefund(payment_plan) {
            this.selectedPaymentPlan = payment_plan;
            this.allowPartial = true;
            this.$bvModal.show('addRefundModal');
            this.addRefundForm = {};
            this.$set(this.addRefundForm, 'certificate_application_id', payment_plan.id);
            this.$set(this.addRefundForm, 'refund_date', moment(new Date()).format('YYYY-MM-DD'));
            this.$set(this.addRefundForm, 'currency', payment_plan.currency);
            this.$set(this.addRefundForm, 'currency_rate', this.data.currencies[payment_plan.currency]);
            this.$refs.addRefundForm.reset();
        },
        async saveRefund() {
            const isValid = await this.$refs.addRefundForm.validate();
            if (isValid) {
                this.addRefundForm.customer_id=this.data.customer.id;
                CertificateRefundService.store(this.addRefundForm)
                    .then(response => {
                        this.addRefundForm = {};
                        this.$refs.addRefundForm.reset();
                        this.toast(response.data);
                        this.$bvModal.hide('addRefundModal');
                        this.loadData();
                    })
                    .catch(error => {
                        this.showErrors(error, this.$refs.addRefundForm);
                    });
            }
        },
        async deleteRefund(id) {
            this.deleteModal(() => {
                CertificateRefundService.deleteRefund(id)
                    .then(response => {
                        this.loadData();
                        if (response.data.success) {
                            this.toast(response.data);
                        } else {
                            this.$toast.error(this.$t('api.' + response.data.message));
                        }
                    })
                    .catch(error => {
                        this.showErrors(error);
                    });
            });

        },
        getPrints(plan) {
            let arr = [{ value: '0', text: 'Yazdır' }];
            var i = 1;
            plan.receipt_numbers.forEach(item => {
                arr.push({
                    value: plan.id.toString() + i.toString(),
                    text: 'Makbuz: ' + item.receipt_number + ' / ' + item.date,
                    type: 'receipt',
                    id: item.receipt_number
                });
                i++;
            });
            plan.printOptions = arr;
            return arr;
        },
        selectPrint(plan) {
            if (plan.printId == '0') {
                return;
            }
            let item = this.getOptionItem(plan.printOptions, plan.printId);
            if (item.type == 'receipt') {
                this.printReceipt(item.id);
            }
        },
        printReceipt(number) {
            if (number == '0') {
                return;
            }
            CertificatePaymentService.getReceipt(number)
                .then(response => {
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const objectUrl = window.URL.createObjectURL(blob)
                    window.open(objectUrl)
                });
        },
        formatToCurrency(value, key, item) {
            return this.$options.filters.formatToCurrency(value, item.currency);
        },
        formatDate(value) {
            return this.$options.filters.dateFormat(value);
        },
        paymentRowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.parent_id) return 'sub-payments'
        },
        customerGroupChanged(val) {
            CustomerService.patch(this.data.customer.id, { group: val.value })
                .then(response => {
                    this.toast(response.data);
                }).catch(e => {
                    this.showErrors(e);
                });
        },
        computedPriceFields(plan) {
            return this.priceFields.filter((field) => {
                return true;
            });
        }
    },
    computed: {
        ...mapGetters(['auth/getActiveRole'])
    }
}
</script>
<style scoped>
*>>>.sub-payments {
    background-color: #e4f5ee !important;
    font-weight: 500;
}

*>>>button {
    white-space: nowrap;
}

*>>>.table-bordered thead tr th {
    background-color: #005baa !important;
    color: #ffffff;
}

*>>>.btn-xs {
    line-height: 24px !important;
    height: 24px !important;
}
</style>
